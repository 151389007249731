
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as manufacturerKGyagn_heBkmcSUS2BqIWZhLDg1ByHXmxKEpXI9kXeoMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/manufacturer.vue?macro=true";
import { default as materialConsumptionCut6oKVV7CAXdsgYyEbVTmwGmGttj_TeMEHhp3_45kSN1j_AMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/materialConsumptionCut.vue?macro=true";
import { default as materialConsumptionEdgeuIpltIc1I8F4xxqZHxwnxikzN2fzUoXpSDIjVPtckEcMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/materialConsumptionEdge.vue?macro=true";
import { default as indexATDudR_0U_qcg2lenWKFbRXJFhchZXgYQLlZ_xldHX4Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/pay/index.vue?macro=true";
import { default as pdfWt_45Nn_45pWz90ORLe7ukjwyBTFqxrR7FGKw_45KnrUdfpyUMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/pay/pdf.vue?macro=true";
import { default as indexXtFeAKM6EtjrJiUxMLlwgCMnLtgbLSEv51BfgXJDouIMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payBuild/index.vue?macro=true";
import { default as pdfprIr4dbhtGkTxq0azw98TRmCp428sIohe05udbpaJ1cMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payBuild/pdf.vue?macro=true";
import { default as indexcn4irPW_45LOmmbojYHCG7TzeI54Jo2_C11dx31YfK7xMMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payFacade/index.vue?macro=true";
import { default as pdf6YNwujz2IQEXKQ9VK4uabXPBgBs6Pwtr09pZDHHoI14Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payFacade/pdf.vue?macro=true";
import { default as indexDwsHrSvVFOMxbLTYCZgBShnwqoZkTgiASDsLW1PrF_450Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMarriageBulkhead/index.vue?macro=true";
import { default as pdfSEsl6h5mESGa1KDCzAHOcMGS8KDX_45qm_I0K5cjriDYMMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMarriageBulkhead/pdf.vue?macro=true";
import { default as indexUn7alKMAfdcY1kTGtxOr_45cUq6I5faopKdU57H0d9dXsMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMirror/index.vue?macro=true";
import { default as pdfISPlm60ViRD0ZhGsylYFHKRbbEeaF_45Rkm9aFEr6nqs0Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMirror/pdf.vue?macro=true";
import { default as stock4n1zPSlIUOSwPMLav1bFPrkD_r_qcXQ3fXf4kGQheWcMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/stock.vue?macro=true";
import { default as indexgi1DgEcapby0C_45ONB3z4vIpEx7HCtmG5C9xhHJDEVFYMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/build/pages/build/index.vue?macro=true";
import { default as dashboardostO8cr5OGym_45D_YwgnR47nSgaiwcSeOIW2nrdRGA9gMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/dashboard.vue?macro=true";
import { default as editrX0H7JaFG3B4wUOVmJQgSWbMgb0aaRbHjF6MjGqbbg8Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/[id]/edit.vue?macro=true";
import { default as archivewi2HDiWNh_45Y4P_45CiWAgAs0ElO1qvqN40mcU6aMddqb8Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/archive.vue?macro=true";
import { default as createa8gH0E_45Mi_eaOSu5OTjyCPmrBGn23rth8Ik1tKWWQNAMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/create.vue?macro=true";
import { default as indexJOvXH3Oma6w7uKFIb2DjwqG79gy2JP2JH2xX_5fIsmMMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/index.vue?macro=true";
import { default as editABs_45ASMGQS9g0ish_45Tlnfhpy3Wj5BejqRV2ihR8gqH0Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/[id]/edit.vue?macro=true";
import { default as archivexv_45_4520yULoN98ydedlHpEar5zH6p9LjZnRwANc00hfIMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/archive.vue?macro=true";
import { default as create17H385wOUKzdiw_vz1RizBdJ_45_CeOYSXX_BJcC_45sc9cMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/create.vue?macro=true";
import { default as indexizuvaIVGXz2g_45JzDsEdmNf75PT1_45Vpp_DPKqldMdrAYMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/index.vue?macro=true";
import { default as editVABS3eD3sXwzxal_453NvMqBsnVO_45t1M1kE2uOM21Hh5kMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/[id]/edit.vue?macro=true";
import { default as archivedxRUbX2qyLNFeGVtBm_453riT3JZsZPyVYU5BUwLqvsq0Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/archive.vue?macro=true";
import { default as create17_RUP_45CRRhtzciJ9uZGEtMXdK2hqLWHkizgVfCjFjkMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/create.vue?macro=true";
import { default as index2v3IobCJfk2ez6CSfitFK8H1_450nVEVsNHRt4jHuJdKYMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/index.vue?macro=true";
import { default as editsrgdbtKsF4os_45PY9A61jdUN6dPLILNQOsrDj9EqiOLcMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/wb/pages/dictionary/wb/[id]/edit.vue?macro=true";
import { default as archivegX4qMgVjbLH_ogItpZF9SX4tmAz4i9CcciQCxq2gLdYMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/wb/pages/dictionary/wb/archive.vue?macro=true";
import { default as createO4xu8vy30ljI7WkXG17Fg0HP5XuweF7aVw9RrF4Q_45pQMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/wb/pages/dictionary/wb/create.vue?macro=true";
import { default as index_45cvCo6PnkJlLVzNvv5PLcp8NV3x2Z9PuhVI1dEFfTQIMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/wb/pages/dictionary/wb/index.vue?macro=true";
import { default as edgeStats9Ch8pmZ6Bo4hxBt0Se3XgyqrwvLxuEs6RsQS27XFlh4Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/edgeStats.vue?macro=true";
import { default as index_0kRDPcx8hmX8z5btCoNiGoJl62Nl3WAWJEVTzO0YpUMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/index.vue?macro=true";
import { default as indexDqUch6ZC8AH_45veU9JZ7WNqI_aOq0g99U7KkGCW0u_45yAMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/manufacturers/pages/manufacturers/[manufacturerId]/index.vue?macro=true";
import { default as createp2iRQ45yKAwrjr040Fkk62ycuAz5bQ_RB3KSwFSgpGsMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/manufacturers/pages/manufacturers/create.vue?macro=true";
import { default as indexPmkLGR_45H05XQ3z3gdDsxm7Ajl6x6qx_45c_YbiznDFQkQMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/marriageBulkhead/pages/marriageBulkhead/index.vue?macro=true";
import { default as indexlNjV2LlPUZA1VPi5VeuHub5h0omgmbBvEcUx2xcUf6QMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/mirror/pages/mirror/index.vue?macro=true";
import { default as indexr8b4KLJ5nF00qu7o4E506edtxX6Ix3YHPfs2W10TAX0Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/fbo/index.vue?macro=true";
import { default as indexchs8BbHLH8oiS4Ma9jRmhB15__45MwDmdKIjdTXF1KWwQMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/fbs/index.vue?macro=true";
import { default as editvk5nv3BL5BQfRVrlBbx6g5n4Zn4FooDsBw8v8qwDdygMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/settings/[id]/edit.vue?macro=true";
import { default as create0_tr6WMKcDsQSJPtJB4aKeSP8qkMUOQXrVDsNw8xxL8Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/settings/create.vue?macro=true";
import { default as indexHi_b9Ht_YttGiFS8ptziB7N0mJGUWSkj4z7H1qxZpl4Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/settings/index.vue?macro=true";
import { default as packTotalT1eP7BjP4WgcFmQg07mclnifxJ29URtZ2Uvo6lTNWTMMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/packTotal.vue?macro=true";
import { default as paymentUsers8Az5U5U2PGrPxs_45sDnCykM_45yjpoZ2crAv543nlAnvu4Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/paymentUsers.vue?macro=true";
import { default as periodsoHEdqJdXBx6OZLTpCB7LhJjHQFbfz3JyTPLsY3T65bAMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periods.vue?macro=true";
import { default as periodsBuildZEUShDxXEThtjBaAYju3GvSp80_45eOt4Qm_Ce9JoS4t4Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periodsBuild.vue?macro=true";
import { default as periodsFacadekQbmjxVlhfohcGLY5EyfWERuYNnQ_gfncmNZJcUYwWsMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periodsFacade.vue?macro=true";
import { default as periodsMarriageBulkheaduHJgkykNdqFChESRSiZKRvJGim0aChHH5Jf7wqGDgdYMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periodsMarriageBulkhead.vue?macro=true";
import { default as periodsWorkPlansoIIGj7_45tfU_jAmjVcfa9VFZkHxwGf_45S_45fQ7F9IlUP_8Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periodsWorkPlans.vue?macro=true";
import { default as editGhRVphEq8lsQZN0nJJ3qhMOQ0fVbnwPmhz9DtBfP7HgMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/[id]/edit.vue?macro=true";
import { default as createCLez_45b25IsU7KrLuvr8CZHAtT_45Z5XHRjG4fNQhPnMpkMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/create.vue?macro=true";
import { default as index_45YnHVlpnSebvXWJQci1sNbXHp9jPheLH3_45OeVFltswAMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/index.vue?macro=true";
import { default as edittQLZGQAj7lwfjikYZWPLoBzNivQa6gPIqdzv2WOmU8kMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/[id]/edit.vue?macro=true";
import { default as createWmc1LamWDIJwZbBt1LW8H_wNAvL0fIATrIm4L5WnOxAMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/create.vue?macro=true";
import { default as index1vOx7fxNtMwyRxPESZ2xACf6flBIvoPkvPZt9FpT4N8Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/index.vue?macro=true";
import { default as createOLNFXZDikV0A1Fj0fU1XGVqrUiDCWd_UT385ktObvjAMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/stock/pages/stock/create.vue?macro=true";
import { default as indexae8aWXpYEFp9qoIjPaSsrndBVQ7dnnFvkYSizP_MIdsMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/stock/pages/stock/index.vue?macro=true";
import { default as edit4gLVcYxwATYlUl0AF6DFt52rKEt_45fNwfpTXtM5oAcdYMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/tags/pages/tags/[id]/edit.vue?macro=true";
import { default as createPri1_f15eJFDlr0nO_VgTceherx6jazFmqUoejEx0sUMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/tags/pages/tags/create.vue?macro=true";
import { default as indexFrqPFDY4r30FSJR_45laN0_S4SqMBOXDD_ApubsCKZMh8Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/tags/pages/tags/index.vue?macro=true";
import { default as editrzdCS_45tjBGO0UHb3CxMF2pAAQ6kVJ3kGUSHvKb1GPm4Meta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/[id]/edit.vue?macro=true";
import { default as archiveKmh4y12nItGYJ9OlsTwO4tYdEXe9jw6H_GcF1CrPJHoMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/archive.vue?macro=true";
import { default as createl2M9SQmSCW_45Bm7lXOzBr687Fvq8xSLoc7X3CAbE_45AjQMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/create.vue?macro=true";
import { default as indexp4b9YQpoJADaApoQjg_453SU2b9gwLXR_45H4LyVu5zoB8MMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/index.vue?macro=true";
import { default as indexHlU_45n6kbyTZgXvJLC5_45G3_avJ0Slj_45uUxa5t0czTrAwMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/workPlans/pages/workPlans/[id]/index.vue?macro=true";
import { default as indexo4RnNedaejno7nZGssSWvTHzqndT9lqTnXE1EFumBlIMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/workPlans/pages/workPlans/pdf/index.vue?macro=true";
import { default as listAT1jwx1h3H_UqNswiLhb7YG1gnkemPElIw8pu2H_45XSwMeta } from "/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/workPlans/pages/workPlans/pdf/list.vue?macro=true";
export default [
  {
    name: "analytics-manufacturer",
    path: "/analytics/manufacturer",
    meta: manufacturerKGyagn_heBkmcSUS2BqIWZhLDg1ByHXmxKEpXI9kXeoMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/manufacturer.vue")
  },
  {
    name: "analytics-materialConsumptionCut",
    path: "/analytics/materialConsumptionCut",
    meta: materialConsumptionCut6oKVV7CAXdsgYyEbVTmwGmGttj_TeMEHhp3_45kSN1j_AMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/materialConsumptionCut.vue")
  },
  {
    name: "analytics-materialConsumptionEdge",
    path: "/analytics/materialConsumptionEdge",
    meta: materialConsumptionEdgeuIpltIc1I8F4xxqZHxwnxikzN2fzUoXpSDIjVPtckEcMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/materialConsumptionEdge.vue")
  },
  {
    name: "analytics-pay",
    path: "/analytics/pay",
    meta: indexATDudR_0U_qcg2lenWKFbRXJFhchZXgYQLlZ_xldHX4Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/pay/index.vue")
  },
  {
    name: "analytics-pay-pdf",
    path: "/analytics/pay/pdf",
    meta: pdfWt_45Nn_45pWz90ORLe7ukjwyBTFqxrR7FGKw_45KnrUdfpyUMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/pay/pdf.vue")
  },
  {
    name: "analytics-payBuild",
    path: "/analytics/payBuild",
    meta: indexXtFeAKM6EtjrJiUxMLlwgCMnLtgbLSEv51BfgXJDouIMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payBuild/index.vue")
  },
  {
    name: "analytics-payBuild-pdf",
    path: "/analytics/payBuild/pdf",
    meta: pdfprIr4dbhtGkTxq0azw98TRmCp428sIohe05udbpaJ1cMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payBuild/pdf.vue")
  },
  {
    name: "analytics-payFacade",
    path: "/analytics/payFacade",
    meta: indexcn4irPW_45LOmmbojYHCG7TzeI54Jo2_C11dx31YfK7xMMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payFacade/index.vue")
  },
  {
    name: "analytics-payFacade-pdf",
    path: "/analytics/payFacade/pdf",
    meta: pdf6YNwujz2IQEXKQ9VK4uabXPBgBs6Pwtr09pZDHHoI14Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payFacade/pdf.vue")
  },
  {
    name: "analytics-payMarriageBulkhead",
    path: "/analytics/payMarriageBulkhead",
    meta: indexDwsHrSvVFOMxbLTYCZgBShnwqoZkTgiASDsLW1PrF_450Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMarriageBulkhead/index.vue")
  },
  {
    name: "analytics-payMarriageBulkhead-pdf",
    path: "/analytics/payMarriageBulkhead/pdf",
    meta: pdfSEsl6h5mESGa1KDCzAHOcMGS8KDX_45qm_I0K5cjriDYMMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMarriageBulkhead/pdf.vue")
  },
  {
    name: "analytics-payMirror",
    path: "/analytics/payMirror",
    meta: indexUn7alKMAfdcY1kTGtxOr_45cUq6I5faopKdU57H0d9dXsMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMirror/index.vue")
  },
  {
    name: "analytics-payMirror-pdf",
    path: "/analytics/payMirror/pdf",
    meta: pdfISPlm60ViRD0ZhGsylYFHKRbbEeaF_45Rkm9aFEr6nqs0Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/payMirror/pdf.vue")
  },
  {
    name: "analytics-stock",
    path: "/analytics/stock",
    meta: stock4n1zPSlIUOSwPMLav1bFPrkD_r_qcXQ3fXf4kGQheWcMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/analytics/pages/analytics/stock.vue")
  },
  {
    name: "build",
    path: "/build",
    meta: indexgi1DgEcapby0C_45ONB3z4vIpEx7HCtmG5C9xhHJDEVFYMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/build/pages/build/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardostO8cr5OGym_45D_YwgnR47nSgaiwcSeOIW2nrdRGA9gMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/dashboard.vue")
  },
  {
    name: "dictionary-products-id-edit",
    path: "/dictionary/products/:id()/edit",
    meta: editrX0H7JaFG3B4wUOVmJQgSWbMgb0aaRbHjF6MjGqbbg8Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/[id]/edit.vue")
  },
  {
    name: "dictionary-products-archive",
    path: "/dictionary/products/archive",
    meta: archivewi2HDiWNh_45Y4P_45CiWAgAs0ElO1qvqN40mcU6aMddqb8Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/archive.vue")
  },
  {
    name: "dictionary-products-create",
    path: "/dictionary/products/create",
    meta: createa8gH0E_45Mi_eaOSu5OTjyCPmrBGn23rth8Ik1tKWWQNAMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/create.vue")
  },
  {
    name: "dictionary-products",
    path: "/dictionary/products",
    meta: indexJOvXH3Oma6w7uKFIb2DjwqG79gy2JP2JH2xX_5fIsmMMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/products/pages/dictionary/products/index.vue")
  },
  {
    name: "dictionary-sheetFormats-id-edit",
    path: "/dictionary/sheetFormats/:id()/edit",
    meta: editABs_45ASMGQS9g0ish_45Tlnfhpy3Wj5BejqRV2ihR8gqH0Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/[id]/edit.vue")
  },
  {
    name: "dictionary-sheetFormats-archive",
    path: "/dictionary/sheetFormats/archive",
    meta: archivexv_45_4520yULoN98ydedlHpEar5zH6p9LjZnRwANc00hfIMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/archive.vue")
  },
  {
    name: "dictionary-sheetFormats-create",
    path: "/dictionary/sheetFormats/create",
    meta: create17H385wOUKzdiw_vz1RizBdJ_45_CeOYSXX_BJcC_45sc9cMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/create.vue")
  },
  {
    name: "dictionary-sheetFormats",
    path: "/dictionary/sheetFormats",
    meta: indexizuvaIVGXz2g_45JzDsEdmNf75PT1_45Vpp_DPKqldMdrAYMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/sheetFormats/pages/dictionary/sheetFormats/index.vue")
  },
  {
    name: "dictionary-stocks-id-edit",
    path: "/dictionary/stocks/:id()/edit",
    meta: editVABS3eD3sXwzxal_453NvMqBsnVO_45t1M1kE2uOM21Hh5kMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/[id]/edit.vue")
  },
  {
    name: "dictionary-stocks-archive",
    path: "/dictionary/stocks/archive",
    meta: archivedxRUbX2qyLNFeGVtBm_453riT3JZsZPyVYU5BUwLqvsq0Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/archive.vue")
  },
  {
    name: "dictionary-stocks-create",
    path: "/dictionary/stocks/create",
    meta: create17_RUP_45CRRhtzciJ9uZGEtMXdK2hqLWHkizgVfCjFjkMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/create.vue")
  },
  {
    name: "dictionary-stocks",
    path: "/dictionary/stocks",
    meta: index2v3IobCJfk2ez6CSfitFK8H1_450nVEVsNHRt4jHuJdKYMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/stocks/pages/dictionary/stocks/index.vue")
  },
  {
    name: "dictionary-wb-id-edit",
    path: "/dictionary/wb/:id()/edit",
    meta: editsrgdbtKsF4os_45PY9A61jdUN6dPLILNQOsrDj9EqiOLcMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/wb/pages/dictionary/wb/[id]/edit.vue")
  },
  {
    name: "dictionary-wb-archive",
    path: "/dictionary/wb/archive",
    meta: archivegX4qMgVjbLH_ogItpZF9SX4tmAz4i9CcciQCxq2gLdYMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/wb/pages/dictionary/wb/archive.vue")
  },
  {
    name: "dictionary-wb-create",
    path: "/dictionary/wb/create",
    meta: createO4xu8vy30ljI7WkXG17Fg0HP5XuweF7aVw9RrF4Q_45pQMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/wb/pages/dictionary/wb/create.vue")
  },
  {
    name: "dictionary-wb",
    path: "/dictionary/wb",
    meta: index_45cvCo6PnkJlLVzNvv5PLcp8NV3x2Z9PuhVI1dEFfTQIMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/dictionaries/wb/pages/dictionary/wb/index.vue")
  },
  {
    name: "edgeStats",
    path: "/edgeStats",
    meta: edgeStats9Ch8pmZ6Bo4hxBt0Se3XgyqrwvLxuEs6RsQS27XFlh4Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/edgeStats.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index_0kRDPcx8hmX8z5btCoNiGoJl62Nl3WAWJEVTzO0YpUMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/index.vue")
  },
  {
    name: "manufacturers-manufacturerId",
    path: "/manufacturers/:manufacturerId()",
    meta: indexDqUch6ZC8AH_45veU9JZ7WNqI_aOq0g99U7KkGCW0u_45yAMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/manufacturers/pages/manufacturers/[manufacturerId]/index.vue")
  },
  {
    name: "manufacturers-create",
    path: "/manufacturers/create",
    meta: createp2iRQ45yKAwrjr040Fkk62ycuAz5bQ_RB3KSwFSgpGsMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/manufacturers/pages/manufacturers/create.vue")
  },
  {
    name: "marriageBulkhead",
    path: "/marriageBulkhead",
    meta: indexPmkLGR_45H05XQ3z3gdDsxm7Ajl6x6qx_45c_YbiznDFQkQMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/marriageBulkhead/pages/marriageBulkhead/index.vue")
  },
  {
    name: "mirror",
    path: "/mirror",
    meta: indexlNjV2LlPUZA1VPi5VeuHub5h0omgmbBvEcUx2xcUf6QMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/mirror/pages/mirror/index.vue")
  },
  {
    name: "orders-fbo",
    path: "/orders/fbo",
    meta: indexr8b4KLJ5nF00qu7o4E506edtxX6Ix3YHPfs2W10TAX0Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/fbo/index.vue")
  },
  {
    name: "orders-fbs",
    path: "/orders/fbs",
    meta: indexchs8BbHLH8oiS4Ma9jRmhB15__45MwDmdKIjdTXF1KWwQMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/fbs/index.vue")
  },
  {
    name: "orders-settings-id-edit",
    path: "/orders/settings/:id()/edit",
    meta: editvk5nv3BL5BQfRVrlBbx6g5n4Zn4FooDsBw8v8qwDdygMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/settings/[id]/edit.vue")
  },
  {
    name: "orders-settings-create",
    path: "/orders/settings/create",
    meta: create0_tr6WMKcDsQSJPtJB4aKeSP8qkMUOQXrVDsNw8xxL8Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/settings/create.vue")
  },
  {
    name: "orders-settings",
    path: "/orders/settings",
    meta: indexHi_b9Ht_YttGiFS8ptziB7N0mJGUWSkj4z7H1qxZpl4Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/orders/pages/orders/settings/index.vue")
  },
  {
    name: "packTotal",
    path: "/packTotal",
    meta: packTotalT1eP7BjP4WgcFmQg07mclnifxJ29URtZ2Uvo6lTNWTMMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/packTotal.vue")
  },
  {
    name: "paymentUsers",
    path: "/paymentUsers",
    meta: paymentUsers8Az5U5U2PGrPxs_45sDnCykM_45yjpoZ2crAv543nlAnvu4Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/paymentUsers.vue")
  },
  {
    name: "periods",
    path: "/periods",
    meta: periodsoHEdqJdXBx6OZLTpCB7LhJjHQFbfz3JyTPLsY3T65bAMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periods.vue")
  },
  {
    name: "periodsBuild",
    path: "/periodsBuild",
    meta: periodsBuildZEUShDxXEThtjBaAYju3GvSp80_45eOt4Qm_Ce9JoS4t4Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periodsBuild.vue")
  },
  {
    name: "periodsFacade",
    path: "/periodsFacade",
    meta: periodsFacadekQbmjxVlhfohcGLY5EyfWERuYNnQ_gfncmNZJcUYwWsMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periodsFacade.vue")
  },
  {
    name: "periodsMarriageBulkhead",
    path: "/periodsMarriageBulkhead",
    meta: periodsMarriageBulkheaduHJgkykNdqFChESRSiZKRvJGim0aChHH5Jf7wqGDgdYMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periodsMarriageBulkhead.vue")
  },
  {
    name: "periodsWorkPlans",
    path: "/periodsWorkPlans",
    meta: periodsWorkPlansoIIGj7_45tfU_jAmjVcfa9VFZkHxwGf_45S_45fQ7F9IlUP_8Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/pages/periodsWorkPlans.vue")
  },
  {
    name: "pricePolicy-id-edit",
    path: "/pricePolicy/:id()/edit",
    meta: editGhRVphEq8lsQZN0nJJ3qhMOQ0fVbnwPmhz9DtBfP7HgMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/[id]/edit.vue")
  },
  {
    name: "pricePolicy-create",
    path: "/pricePolicy/create",
    meta: createCLez_45b25IsU7KrLuvr8CZHAtT_45Z5XHRjG4fNQhPnMpkMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/create.vue")
  },
  {
    name: "pricePolicy",
    path: "/pricePolicy",
    meta: index_45YnHVlpnSebvXWJQci1sNbXHp9jPheLH3_45OeVFltswAMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/pricePolicy/pages/pricePolicy/index.vue")
  },
  {
    name: "roles-id-edit",
    path: "/roles/:id()/edit",
    meta: edittQLZGQAj7lwfjikYZWPLoBzNivQa6gPIqdzv2WOmU8kMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/[id]/edit.vue")
  },
  {
    name: "roles-create",
    path: "/roles/create",
    meta: createWmc1LamWDIJwZbBt1LW8H_wNAvL0fIATrIm4L5WnOxAMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/create.vue")
  },
  {
    name: "roles",
    path: "/roles",
    meta: index1vOx7fxNtMwyRxPESZ2xACf6flBIvoPkvPZt9FpT4N8Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/roles/pages/roles/index.vue")
  },
  {
    name: "stock-create",
    path: "/stock/create",
    meta: createOLNFXZDikV0A1Fj0fU1XGVqrUiDCWd_UT385ktObvjAMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/stock/pages/stock/create.vue")
  },
  {
    name: "stock",
    path: "/stock",
    meta: indexae8aWXpYEFp9qoIjPaSsrndBVQ7dnnFvkYSizP_MIdsMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/stock/pages/stock/index.vue")
  },
  {
    name: "tags-id-edit",
    path: "/tags/:id()/edit",
    meta: edit4gLVcYxwATYlUl0AF6DFt52rKEt_45fNwfpTXtM5oAcdYMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/tags/pages/tags/[id]/edit.vue")
  },
  {
    name: "tags-create",
    path: "/tags/create",
    meta: createPri1_f15eJFDlr0nO_VgTceherx6jazFmqUoejEx0sUMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/tags/pages/tags/create.vue")
  },
  {
    name: "tags",
    path: "/tags",
    meta: indexFrqPFDY4r30FSJR_45laN0_S4SqMBOXDD_ApubsCKZMh8Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/tags/pages/tags/index.vue")
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: editrzdCS_45tjBGO0UHb3CxMF2pAAQ6kVJ3kGUSHvKb1GPm4Meta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/[id]/edit.vue")
  },
  {
    name: "users-archive",
    path: "/users/archive",
    meta: archiveKmh4y12nItGYJ9OlsTwO4tYdEXe9jw6H_GcF1CrPJHoMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/archive.vue")
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: createl2M9SQmSCW_45Bm7lXOzBr687Fvq8xSLoc7X3CAbE_45AjQMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/create.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexp4b9YQpoJADaApoQjg_453SU2b9gwLXR_45H4LyVu5zoB8MMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/users/pages/users/index.vue")
  },
  {
    name: "workPlans-id",
    path: "/workPlans/:id()",
    meta: indexHlU_45n6kbyTZgXvJLC5_45G3_avJ0Slj_45uUxa5t0czTrAwMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/workPlans/pages/workPlans/[id]/index.vue")
  },
  {
    name: "workPlans-pdf",
    path: "/workPlans/pdf",
    meta: indexo4RnNedaejno7nZGssSWvTHzqndT9lqTnXE1EFumBlIMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/workPlans/pages/workPlans/pdf/index.vue")
  },
  {
    name: "workPlans-pdf-list",
    path: "/workPlans/pdf/list",
    meta: listAT1jwx1h3H_UqNswiLhb7YG1gnkemPElIw8pu2H_45XSwMeta || {},
    component: () => import("/var/www/raspil12_ffa_usr/data/www/raspil12.ffa.su/modules/workPlans/pages/workPlans/pdf/list.vue")
  }
]